<template>
    <div class="page-container">
        <div class="header-content box-j">
            <div>
                <div class="box-l">
                    <img class="logo" src="@/assets/logo.png" /> 
                    <p>MBO</p>
                </div>
            </div>
            <NavBreadCrumb />
        </div>
    <div>
    </div>
        <div class="box-l">
            <el-scrollbar>
                <el-menu
                ref="sidebar"
                class="page-menu-wrapper"
                :default-active="activeMenu"
                @select="onChangeMenu"
                :collapse="pageData.isCollapse"
                >  
                <template v-for="(menu, index) in menus">
                    <template v-if="!menu.children">
                    <el-menu-item
                        v-show="menu.meta && !menu.meta.hidden"
                        :key="index"
                        :index="menu?.name"
                        @click="() => onMenuClick(menu.path)"
                    >
                        <el-icon>
                        <!-- v-if="menu.meta && menu.meta.icon" -->
                        <el-badge
                            :max="99"
                            :value="menu.meta?.badge"
                            :hidden="!menu.meta?.badge || menu.meta?.badge <= 0"
                        >
                            <component :is="menu.meta.icon" />
                        </el-badge>
                        </el-icon>
                        <template #title>{{
                        menu.meta ? menu.meta.title : ''
                        }}</template>
                    </el-menu-item>
                    </template>
                    <el-sub-menu v-else :index="menu?.name" :key="`${index}-n`">
                    <template #title>
                        <el-icon v-show="menu.meta && menu.meta.icon">
                        <el-badge
                            :max="99"
                            :value="menu.meta?.badge"
                            :hidden="!menu.meta?.badge || menu.meta?.badge <= 0"
                        >
                            <component :is="menu.meta.icon" />
                        </el-badge>
                        </el-icon>
                        <span>{{ menu.meta ? menu.meta.title : '' }}</span>
                    </template>
                    <template v-for="(subMenu, subIndex) in menu.children">
                        <template v-if="!subMenu.children">
                        <el-menu-item
                            v-if="subMenu.meta && !subMenu.meta.hidden"
                            @click="() => onMenuClick(`${menu.path}/${subMenu.path}`)"
                            :index="subMenu?.name"
                            :key="`${index}-${subIndex}`"
                        >
                            <el-icon v-if="subMenu.meta && subMenu.meta.icon">
                            <el-badge
                                :max="99"
                                :value="subMenu.meta?.badge"
                                :hidden="
                                !subMenu.meta?.badge || subMenu.meta?.badge <= 0
                                "
                            >
                                <component :is="subMenu.meta.icon" />
                            </el-badge>
                            </el-icon>
                            <template #title>{{
                            subMenu.meta ? subMenu.meta.title : ''
                            }}</template>
                        </el-menu-item>
                        </template>
                        <el-sub-menu :index="subMenu?.name" v-else>
                        <template #title>
                            <el-icon v-if="subMenu.meta && subMenu.meta.icon">
                            <el-badge
                                :max="99"
                                :value="subMenu.meta?.badge"
                                :hidden="
                                !subMenu.meta?.badge || subMenu.meta?.badge <= 0
                                "
                            >
                                <component :is="subMenu.meta.icon" />
                            </el-badge>
                            </el-icon>
                            <span>{{ subMenu.meta ? subMenu.meta.title : '' }}</span>
                        </template>
                        <template v-for="(thirdMenu, thirdIndex) in subMenu.children">
                            <el-menu-item
                            v-if="thirdMenu.meta && !thirdMenu.meta.hidden"
                            :key="`${index}-${subIndex}-${thirdIndex}`"
                            :index="subMenu?.name"
                            @click="
                                () =>
                                onMenuClick(
                                    `${menu.path}/${subMenu.path}/${thirdMenu.path}`
                                )
                            "
                            >
                            <el-icon v-if="thirdMenu.meta && thirdMenu.meta.icon">
                                <el-badge
                                :max="99"
                                :value="thirdMenu.meta?.badge"
                                :hidden="
                                    !thirdMenu.meta?.badge || thirdMenu.meta?.badge <= 0
                                "
                                >
                                <component :is="thirdMenu.meta.icon" />
                                </el-badge>
                            </el-icon>
                            <template #title>{{
                                thirdMenu.meta ? thirdMenu.meta.title : ''
                            }}</template>
                            </el-menu-item>
                        </template>
                        </el-sub-menu>
                    </template>
                    </el-sub-menu>
                </template>
                
                </el-menu>
            </el-scrollbar>
            <div class="collapse">
                <el-tooltip
                    effect="dark"
                    :content="pageData.isCollapse?'展开菜单':'折叠菜单'"
                    placement="right"
                >
                    <el-icon @click="pageData.isCollapse=!pageData.isCollapse">
                        <DArrowRight v-if="pageData.isCollapse"  />
                        <DArrowLeft v-else />
                    </el-icon>
                </el-tooltip>
            </div>
            <div class="box-flex1" style="padding: 10px 15px 10px 0; height: calc(100vh - 64px);overflow: auto;">
                <div class="page-content">
                    <KeepAlive v-if="route.meta.keepAlive">  
                        <router-view  />  
                    </KeepAlive>  
                    <router-view v-else :key="route.fullPath"  />  
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { shallowRef, computed, watch, onMounted } from 'vue'
import { useRouter, useRoute, RouteRecordRaw } from 'vue-router'
import { useStore } from 'vuex'
import NavBreadCrumb from './breadcrumb.vue'
import setupState from './setup/menuState'
const { setIsCollapse } = setupState()
const store = useStore()
const router = useRouter()
const route = useRoute()

const sidebar = shallowRef<any>(null)
const activeMenu = shallowRef<any>('')
// const isCollapse = computed(() => store.getters.getIsCollapse)
const contentPadding = computed(() => (store.getters.getIsCollapse ? 63 : 200))
const menus = computed(() => store.getters.getMenu)
const pageData = reactive({
	isCollapse: false
})

// 强制刷新Menu
watch(
  () => store.getters.refresh,
  () => {
    if (sidebar) sidebar.value?.$forceUpdate()
  }
)

watch(route, (to) => {
  if (to.name !== activeMenu.value) {
    activeMenu.value = to.meta.father || to.name
  }
})

const onChangeMenu = (index) => {
  activeMenu.value = index
}

const onMenuClick = (path: string) => {
    console.log(path,'path')
  router.push(`/${path}`)
}

onMounted(() => {
  const [, menu] = menus.value
  const { father } = route?.meta
  let activeName = father || route?.name
  activeMenu.value = activeName
})
</script>
<style lang="scss" scoped>
.page-container {
  // display: flex;
  width: 100%;
  min-height: 100vh;

  .page-menu {
    // position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 1;
    min-width: 200px;
    min-height: calc(100vh - 64px);
    overflow: auto;

    &.el-menu--collapse {
      min-width: 64px;
    }
}
.page-menu-wrapper{
  height: calc(100vh - 64px);
 
  
}
.collapse{
   padding: 0 7px;
   cursor: pointer;
}

  .page-content { 
    // background: #fff;
    // border-radius: 10px;
    // padding: 20px;
    min-height:100%;
    box-sizing: border-box;
  }
}

.header-content{
  padding: 0 34px 0 16px;
  width: 100vw;
  height: 50px;
  color: #fff;
  font-family: PingFang SC;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  background: #1890FF;
  .logo{
    width: 107px;
    height: 24px;
    display: block;
    margin-right: 16px;
  }
}

.el-menu-item {
  text-align: center;
  width: 100%;
}
.menu-width {
  width: 20px;
  position: absolute;
  bottom: 116px;
  left: 137px;
}
.menu-small {
  width: 20px;
  position: absolute;
  bottom: 116px;
  left: 30px;
}
</style>
