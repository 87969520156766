import axios from 'axios'
import network, { NetworkRequestConfig } from './network'
// import oss from './oss'
import loginapi from './login'
// 模拟 network
const mocknetwork = (data, delay = 500, pass = true) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (pass) {
        resolve(data)
      } else {
        reject(null)
      }
    }, delay)
  })
}
// 权限接口
export const getGlobalUserInfo = () => {
  return network({
    url: '/getInfo',
    method: 'get',
  })
}
// 国家列表
export const CountryList = () => {
  return axios.get(
    'https://appletservice.oss-cn-hangzhou.aliyuncs.com/country.json'
  )
}
// 商户列表
export const AllMerchantList = () => {
  return network({
    url: '/acct/getAllMerchantSummary',
    method: 'get',
  })
}
// 账号列表
export const AllAccountList = () => {
  return network({
    url: '/acct/getAllMerchantAcct',
    method: 'get',
  })
}
// new Promise((resolve) => {
//   // @ts-ignore
//   setTimeout(
//     () =>
//       resolve({
//         success: true,
//         data: {
//           id: 1,
//           name: '示例用户',
//           phone: 'xxxxx',
//           avatar:
//             'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
//           permissions: [],
//           unreadNotice: Math.round(Math.random() * 10),
//         },
//       }),
//     1000
//   )
// }) as any
//请求拦截器
export const getGlobalUserInfoDemo = () => network.get('xxxxxx')
// 登录模块
export const Login = (data) => {
  return loginapi({
    url: '/login',
    data: data,
    method: 'POST',
  })
}
// 验证码
export const ImgCode = () => {
  return loginapi({
    url: '/captchaImage',
    method: 'get',
  })
  //url: 'http://192.168.124.27:8788//auth/code',
}
// Mer
export const Merchlist = (data) => {
  return network({
    url: '/acct/queryAcctList',
    method: 'post',
    data,
  })
}
export const Demerch = (data) => {
  return network({
    url: '/acct/queryMerchantAcctList',
    method: 'post',
    data: data,
  })
}
// 争议
export const DispList = (data) => {
  return network({
    url: '/disputes/queryMerchantDisputesList',
    method: 'post',
    data: data,
  })
}
// 订单详情
export const DetailList = (data) => {
  return network({
    url: '/acct/payments/' + data.paymentIntentId,
    method: 'get',
  })
}
// 新增争议
export const Adisp = (data) => {
  return network({
    url: '/disputes/createDispute',
    method: 'post',
    data: data,
  })
}
// 正义金额判断
export const Oramount = (data) => {
  return network({
    url: '/disputes/createDisputeValidate',
    method: 'post',
    data: data,
  })
}
// 争议原因
export const Detailres = (data) => {
  return network({
    url: '/disputes/queryDisputeReason',
    method: 'post',
    data,
  })
}
// 争议详情
export const DetailDeta = (data) => {
  return network({
    url: '/disputes/' + data?.id,
    method: 'get',
  })
}
// 查询证据
export const GetDet = (data) => {
  return network({
    url: '/disputes/evidence/' + data.disputeId,
    method: 'get',
  })
}
// 审核状态
export const poststatus = (data) => {
  return network({
    url: '/disputes/evidence/managerMerchantDisputeResult',
    method: 'POST',
    data,
  })
}
// 下载
export const dropdist = (data) => {
  return network({
    url: '/disputes/evidence/download/' + data.disputeId,
    method: 'get',
    responseType: 'blob',
  })
}
// 详情部分模块
// balacne模块
export const mbobalancelist = (data) => {
  return network({
    url: '/acct/balance/query/multiAcctBalance',
    method: 'post',
    data: data,
  })
}

// /acct/balance/updateAcctBalance
export const mbobalanedit = (data) => {
  return network({
    url: '/acct/balance/operate/multiAcctBalance',
    method: 'post',
    data,
  })
}
export const applaybalance = (data) => {
  return network({
    url: '/acct/balance/operate/merchant/multiAcctBalance',
    method: 'post',
    data,
  })
}
export const mbobalandel = (data) => {
  return network({
    url: '/acct/balance/delMultiAcctBalance',
    method: 'post',
    data,
  })
}
//
export const mbogenerlist = (data) => {
  return network({
    url: '/acct/generalInfo/queryGeneralInfo',
    method: 'post',
    data: data,
  })
}
export const mbokeylist = (data) => {
  return network({
    url: '/acct/apiKey/queryAcctApiKey',
    method: 'post',
    data,
  })
}
export const mbodashbord = (data) => {
  return network({
    url: '/acct/dashboard/queryDashboardAccount',
    method: 'post',
    data,
  })
}
// 
export const Checkupload = (data) => {
  return network({
    url: '/disputes/file/upload/check',
    method: 'post',
    data,
  })
}
// 新增acct列表
export const mbodashadd = (data) => {
  return network({
    url: '/acct/createMerchantAcct',
    method: 'post',
    data,
  })
}
// 修改日志
export const sawmbobal = (data) => {
  return network({
    url: '/acct/balance/queryAcctBalanceLog',
    method: 'post',
    data,
  })
}
export const dashadd = (data) => {
  return network({
    url: '/acct/dashboard/createDashboardAccount',
    method: 'post',
    data,
  })
}
// 修改状态
export const updatedash = (data) => {
  return network({
    url: '/acct/dashboard/updateDashboardAccount',
    method: 'post',
    data,
  })
}
// 重新生成key
export const upkey = (data) => {
  return network({
    url: '/acct/apiKey/resetAcctApiKey',
    method: 'post',
    data,
  })
}
// 批量上传
// export const upload = () => {
//   return oss.get('/oss/authorize')
// }
// 1216
export const checkflie = (data) => {
  return network({
    url: '/disputes/file/upload/check',
    method: 'post',
    data,
    // headers: {
    //   'Accept': '*/*',
    //   'Content-Type': 'multipart/form-data'
    // }
  })
}
export const uploadfile = (data) => {
  return network({
    url: '/disputes/file/upload/',
    method: 'post',
    data,
  })
}
// oss 鉴权
export const OssAuth = () => {
  return network({
    url: '/acct/oss/authorize',
    method: 'get',
  })
}
// oss 上传
export const OssUpload = (action, authObj) => {
  return axios.post(action, authObj)
}
// reports daily 列表
export const ReportsDailyList = (data) => {
  return network({
    url: '/reports/daily/list',
    method: 'post',
    data,
  })
}
// reports daily save
export const ReportsDailySave = (data) => {
  return network({
    url: '/reports/daily/saveMerchantReport',
    method: 'post',
    data,
  })
}
// reports daily search all
export const ReportsDailyQueryAllByAcct = (data, config?: NetworkRequestConfig) => {
  const { acctId } = data
  return network({
    url: `/reports/daily/queryAllMerchantByAcct?acctId=${acctId}`,
    ...config
  })
}
// reports daily search
export const ReportsDailyQueryByAcct = (data, config?: NetworkRequestConfig) => {
  const { acctId } = data
  return network({
    url: `/reports/daily/queryMerchantByAcct?acctId=${acctId}`,
    ...config
  })
}
// reports daily download
export const ReportsDailyDownload = (data, config?: NetworkRequestConfig) => {
  return network({
    url: `/reports/daily/downMerchantReport`,
    method: 'post',
    data,
    ...config
  })
}
// reports列表
export const ReportsList = (data) => {
  return network({
    url: '/reports/list',
    method: 'post',
    data,
  })
}
// reports 文件删除
export const ReportsDelete = (fileId) => {
  return network({
    url: `/reports/deleteFile/${fileId}`,
    method: 'delete',
  })
}
// reports 文件下载
export const ReportsDownload = (fileId) => {
  return network({
    url: `/reports/download/${fileId}`,
    method: 'get',
    responseType: 'blob',
  })
}
// reports 批量文件上传
export const ReportsBatchUpload = (data) => {
  return network({
    url: '/reports/batchUpload',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data
  })
}
// merchant列表
export const MerchantList = (data) => {
  return network({
    url: '/reports/merchantAccount',
    method: 'post',
    data,
  })
}
// reports 提交
export const ReportsUpload = (data) => {
  return network({
    url: '/reports/fileUpload/transmission',
    method: 'post',
    data,
    errorType: 'messageBox'
  })
}
// reconciliations列表
export const ReconciliationsList = (data) => {
  return network({
    url: `/reconciliations/search`,
    method: 'post',
    data,
  })
}
// reconciliations下载
export const ReconciliationsUpload = (data) => {
  return network({
    url: '/reconciliations/file/upload',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data
  })
}
// reconciliations解析
export const ReconciliationsParse = (data) => {
  return network({
    url: '/reconciliations/parse',
    method: 'post',
    data
  })
}
// reconciliations下载
export const ReconciliationsDownload = (url) => {
  return network({
    url: `/reconciliations/file/download?filePath=${url}`,
    method: 'get',
    responseType: 'blob'
  })
}
// fees daily列表
export const FeesDailyList = (data) => {
  return network({
    url: '/fees/daily-reports/search',
    method: 'post',
    data
  })
}
// fees daily下载
export const FeesDailyDownload = (data) => {
  return network({
    url: '/fees/daily-reports/download',
    method: 'post',
    responseType: 'blob',
    data
  })
}
// fees billing列表
export const FeesBillingList = (data) => {
  return network({
    url: '/fees/details/search',
    method: 'post',
    data
  })
}
// fees billing下载
export const FeesBillingDownload = (data) => {
  return network({
    url: '/fees/details/download',
    method: 'post',
    responseType: 'blob',
    data
  })
}
// quotation 报价单
export const QuotationList = (data) => {
  return network({
    url: '/fees/quotation/query',
    method: 'post',
    data: {
      page: 1,
      size: 1000,
      ...data
    }
  })
}
// quotation 下载
export const QuotationDownload = (id) => {
  return network({
    url: '/fees/quotation/details/export',
    method: 'post',
    responseType: 'blob',
    data: {
      page: 1,
      size: 1000,
      id
    }
  })
}
// quotation 删除
export const QuotationDelete = (id) => {
  return network({
    url: '/fees/quotation/delete',
    method: 'delete',
    data: { id }
  })
}
// quotation 新增
export const QuotationAdd = (data) => {
  return network({
    url: '/fees/quotation/add',
    method: 'post',
    data
  })
}
// quotation 更新
export const QuotationUpdate = (data) => {
  return network({
    url: '/fees/quotation/update',
    method: 'post',
    data
  })
}
// fees adjustment 添加
export const AdjustmentAdd = (data) => {
  return network({
    url: '/fees/ajustment/add',
    method: 'post',
    data
  })
}
// fees adjustment 更新
export const AdjustmentUpdate = (data) => {
  return network({
    url: `/fees/ajustment/update/`,
    method: 'delete',
    data
  })
}
// fees adjustment 删除
export const AdjustmentDelete = (id) => {
  return network({
    url: `/fees/ajustment/delete/${id}`,
    method: 'delete'
  })
}
export const queryUsc = (mid) => {
  return network({
    url: `acct/queryUsc/${mid}`,
    method: 'get'
  })
}
export const updateUsc = (data) => {
  return network({
    url: `acct/updateUsc`,
    method: 'post',
    data
  })
}
// FxBoard 列表
export const FxBoardList = (data) => {
  return network({
    url: "/fees/fxboard/query",
    method: "post",
    data
  })
}
// FxBoard 详情
export const FxBoardDetail = (data) => {
  return network({
    url: "/fees/fxboard/details",
    method: "post",
    data
  })
}
// FxBoard 详情
export const FxBoardUpdate = (data) => {
  return network({
    url: "/fees/fxboard/details/update",
    method: "put",
    data
  })
}
// Refund 列表
export const RefundList = (data) => {
  return network({
    url: '/acct/refunds/list',
    method: 'post',
    data
  })
}
// Refund 上传
export const RefundCheck = (data) => {
  return network({
    url: '/acct/refunds/file/check',
    method: 'post',
    data
  })
}
// Refund 上传
export const RefundCreate = (data) => {
  return network({
    url: '/acct/refunds/batch/create',
    method: 'post',
    data
  })
}
// Statistics 列表
export const StatisticsList = (data) => {
  return network({
    url: '/minimonitor/query/querytotal',
    method: 'post',
    data
  })
}
// Statistics 列表
export const StatisticsDetail = (data) => {
  return network({
    url: '/minimonitor/query/querydetail',
    method: 'post',
    data
  })
}
// 支付方式查询
export const PaymentMethod = (id) => {
  return network({
    url: `/acct/pm/query/dubbo/${id}`,
    method: 'get'
  })
}
// 查询渠道账号信息
export const ChannelDetail = (data) => {
  return network({
    url: `/acct/queryMerchantAcctList`,
    method: 'post',
    data
  })
}
// Holiday 列表
export const HolidayList = (data) => {
  return network({
    url: '/fees/holiday/query',
    method: 'post',
    data
  })
}
// Holiday 添加
export const HolidayAdd = (data) => {
  return network({
    url: '/fees/holiday',
    method: 'post',
    data
  })
}
// Holiday 删除
export const HolidayDel = (id) => {
  return network({
    url: `/fees/holiday/${id}`,
    method: 'delete'
  })
}
// ------------------------------------------
// Billing 列表
export const BillingStatementList = (data) => {
  return network({
    url: "/fees/daily-reports/queryCalDailyReports",
    method: "post",
    data
  })
}
// 结算配置
export const Settlements = (acctId) => {
  return network({
    url: `/acct/settlement/conf/query/${acctId}`,
    method: "get",
  })
}
// 结算货币类型
export const CurrencyList = () => {
  return network({
    url: "/sys/config/settlementCurrencySupportList",
    method: "get",
  })
}
// 币种精度
export const PrecisionList = () => {
  return axios.get(
    'https://appletservice.oss-cn-hangzhou.aliyuncs.com/precision.json'
  )
}
// 新增/编辑结算配置
export const SaveSettlements = (data) => {
  return network({
    url: "/acct/settlement/conf/createOrRefresh",
    method: "post",
    data,
  })
}
// payouts列表
export const PayoutList = (data) => {
  return network({
    url: "/acct/payouts/queryAcctPayoutsList",
    method: "post",
    data,
  })
}

// BatchPayout列表
export const BatchPayoutListQuery = (data) => {
  return network({
    url: "/acct/payouts/queryBatchPayoutsList",
    method: "post",
    data,
  })
}

export const downloadOssFile = (data) => {
  return network({
    url: data.url,
    method: 'get',
    responseType: 'blob',
    addHttpHeader: false
  })
}

export const updatePayoutBatchStatus = (data) => {
  return network({
    url: "/acct/payouts/updatePayoutBatchStatus",
    method: "post",
    data,
  })
}

export const updatePayoutBatchApi = (data) => {
  return network({
    url: "acct/payouts/updatePayoutBatch",
    method: "post",
    data,
  })
}




// beneficiaryList 列表
export const BeneficiaryTable = (data) => {
  return network({
    url: "/acct/settlement/beneficiary/searchBeneficiary",
    method: "post",
    data,
  })
}
// Payout状态管理
export const PayoutManage = (data) => {
  return network({
    url: `/acct/payouts/updatePayoutStatus`,
    method: "post",
    data,
  })
}
// payouts详情
export const PayoutDetail = (data) => {
  const { payoutId, acctId } = data
  return network({
    url: `/acct/payouts/queryAcctPayoutDetail/${payoutId}&&${acctId}`,
    method: "get"
  })
}
// Beneficiary详情
export const BeneficiaryDetail = (beneficiaryId) => {
  return network({
    url: `/acct/settlement/beneficiary/queryDetail/${beneficiaryId}`,
    method: "get"
  })
}
// Beneficiary 列表
export const BeneficiaryList = (acctId, isDefault) => {
  return network({
    url: `/acct/settlement/beneficiary/querySummaryList?acctId=${acctId}`,
    method: "get",
  })
}
// Beneficiary 删除
export const DeleteBeneficiary = (beneficiaryId) => {
  return network({
    url: `/acct/settlement/beneficiary/del/${beneficiaryId}`,
    method: "get",
  })
}
// 修改/保存 Beneficiary
export const SaveBeneficiary = (data) => {
  return network({
    url: "/acct/settlement/beneficiary/createOrRefresh",
    method: "post",
    data
  })
}
// 修改/保存 Payout
export const SavePayout = (data) => {
  return network({
    url: "/acct/payouts/createOrRefresh",
    method: "post",
    data
  })
}
// Payment 列表
export const PaymentList = (data) => {
  return mocknetwork({
    list: [
      {
        id: '1000',
        merchantOrderId: "1616516512116516",
        productName: "Pick up Service",
        paymentAmount: "300 GBP",
      },
      {
        id: '1001',
        merchantOrderId: "1616516512116516",
        productName: "Pick up Service",
        paymentAmount: "220 CNY",
      },
      {
        id: '1002',
        merchantOrderId: "1616516512116516",
        productName: "Pick up Service",
        paymentAmount: "3,200 JPY",
      },
    ],
  })
}
// Payment 列表
export const PaymentDetail = (data) => {
  return mocknetwork({
    code: 200,
    data: {
      paymentId: '1001',
      orderCreatedDate: '2023-08-09',
      customer: 'Zhang Wei',
      merchantOrderId: '102103123',
      productName: 'sada',
      tradeQuantity: '0.98',
      country: 'China',
      tradeStatus: 'Success',
      currency: 'CNY',
      paymentAmount: '203.109',
      merchantName: 'BaiDu',
      trackingNumber: '1002'
    }
  })
}
// Payment 删除
export const DeletePayment = (id) => {
  return mocknetwork({
    code: 200,
    msg: '操作成功'
  })
}
// Payment 列表
export const SavePayment = (data) => {
  return mocknetwork({
    code: 200,
    msg: '操作成功'
  })
}

export const querySourceAmountFee = (data) => {
  return network({
    url: '/acct/payouts/querySourceAmountFee',
    method: 'post',
    data
  })
}


export const queryExchangeAmount = (data) => {
  return network({
    url: '/acct/payouts/queryExchangeAmount',
    method: 'post',
    data
  })
}
