import { createApp } from 'vue'
import * as Sentry from "@sentry/vue"

import '@/styles/index.scss' // 全局公用样式

import setRouter from './router' // 注册路由
import setStore from './store' // 注册Vuex
import setIcons from './icons' // 注册Icons
import setConfig from './config' // 注册全局配置
import setElementPlus from './elementPlus' // 加载Element组件个性化配置
import App from './App.vue'
const isProd = process.env.NODE_ENV == 'production'
if (import.meta.env.VITE_APP_BASE_API == 'production') {
  console.log = () => {}
}



const Bootstrap = () => {
  const app = createApp(App)
  app.config.warnHandler = () => null
  if (isProd) {
    Sentry.init({
      app,
      dsn: "https://144fea4fcd6153a40f27b77d49646975@o4505917579067392.ingest.sentry.io/4505939079725056",
      integrations: [
        new Sentry.BrowserTracing(),
        new Sentry.Replay()
      ],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0
    })
  }
  app.config.globalProperties.$filters = {
    formatDate(timestamp) {
      const date = new Date(timestamp);
      console.log(333)
      return date.toLocaleDateString(); // 根据需要格式化日期
    }
  };

  setElementPlus(app)
  setIcons(app)
  setStore(app)
  setRouter(app)
  setConfig(app)

  app.mount('#app')
}

// 初始化应用
Bootstrap()
